<!-- used only for mobile version  -->
<template>
    <div class="container p_albums_list" :class="{'discovery': discoveryMode.value === true}">
        <div id="top-anchor"></div>
        <div class="head_bar">

            <div class="subgenre_name">
                <template v-if="id && id !== 'all_new'">{{ SUBGENRE_INFO.name }}</template>
                <template v-else>All new</template>
            </div>
            <div class="buttons_row">
                <button type="button" class="play_all" @click="play('all')" v-ripple :class="{active: variantPlay === 'all'}">
                    <span class="icon"></span>
                    Play all
                </button>
                <button type="button" class="shuffle" @click="play('shuffle')" v-ripple :class="{active: variantPlay === 'shuffle'}">
                    <span class="icon"></span>
                    Shuffle
                </button>
            </div>
            <div class="discovery_mode" @click="clickDiscovery">
                Discovery Mode
                <v-switch
                    v-model="discoveryMode.value"
                    :disabled="discoveryMode.disable"
                    color="#20C4F5"
                ></v-switch>
            </div>
        </div>
        <div class="wrap_grid_albums">
            <template v-if="EXPLORE_ALBUMS_LOADING">
                <v-skeleton-loader
                    v-for="index in 15"
                    :key="index"
                    type="card"
                    class="mobile_subgenres_grid">
                </v-skeleton-loader>
                <!--                <albumGridSkeletonLoader v-for="index in 30" :key="index" :parent="'default_grid_albums'"></albumGridSkeletonLoader>-->
            </template>
            <template v-if="!EXPLORE_ALBUMS_LOADING">
                <albumGrid
                    v-for="album of EXPLORE_GRID_ALBUMS"
                    :album="album"
                    :key="album.id"
                    :discoveryStatus="DISCOVERY"
                    :playZone="EXPLORE_GRID_ALBUMS"
                    :isFavouriteSong="'allSong'">
                </albumGrid>
            </template>
        </div>

        <pagination v-if="EXPLORE_GRID_ALBUMS.length && EXPLORE_GRID_ALBUMS > 30"
                    :count="COUNT_EXPLORE_GRID_ALBUMS"
                    :currentPage="ALBUMS_CURRENT_PAGE"
                    :commit="'SET_ALBUMS_CURRENT_PAGE'">
        </pagination>
        <dialogNeedLogin v-if="dialogNeedLoginVisible" v-model="dialogNeedLoginVisible"></dialogNeedLogin>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import albumGrid from "@/components/musicReleases/albumGrid";
import pagination from "@/components/pagination";

const dialogNeedLogin = () => import('@/components/dialogs/needLogin');
import albumGridSkeletonLoader from "@/components/small/skeleton-loaders/albumGridSkeletonLoader";

export default {
    name: "albumsList",
    components: {
        albumGrid,
        pagination,
        dialogNeedLogin,
        albumGridSkeletonLoader
    },
    props: ['id'], // subgenre id
    data() {
        return {
            discoveryMode: {
                value: false,
                disable: true,
            },

            dynamicKey: 0,
            dialogNeedLoginVisible: false,
            variantPlay: '',
        }
    },
    watch: {
        'discoveryMode.value'() {
            if (this.AUTH_DATA.accessToken) {
                this.$store.dispatch('UPDATE_DISCOVERY', this.discoveryMode.value);
                this.getAlbums();
            } else {
                this.discoveryMode.value = false;
            }
        },
        ALBUMS_CURRENT_PAGE() {
            this.getAlbums();
            this.scrollTop();
        },
    },
    computed: {
        ...mapGetters(['AUTH_DATA',
            'EXPLORE_GRID_ALBUMS', 'EXPLORE_ALBUMS_LOADING', 'COUNT_EXPLORE_GRID_ALBUMS', 'ALBUMS_CURRENT_PAGE', 'FULL_ALBUM',
            'SUBGENRE_INFO', 'SUBGENRES', 'SUBGENRE', 'DISCOVERY',
            'CURRENT_ALBUM', 'PLAYING', 'SHUFFLE', 'CURRENT_PAGE']),
    },
    created() {
        if (this.AUTH_DATA.accessToken) {
            this.discoveryMode.disable = false;
        }
        this.discoveryMode.value = this.DISCOVERY;
    },
    mounted() {
        if (this.id) {
            const params = {id: this.id}
            this.GET_SUBGENRE(params)
                .then(() => {
                    // console.log('created subgenre,', this.SUBGENRE_INFO.name);
                })
                .catch(err => console.log(`GET_SUBGENRE, ${err}`));
        }
        if (!this.EXPLORE_GRID_ALBUMS.length) {
            this.getAlbums();
        }
    },
    methods: {
        clickDiscovery() {
            if (!this.AUTH_DATA.accessToken) {
                this.dialogNeedLoginVisible = true;
            }
        },
        ...mapActions(['GET_EXPLORE_GRID_ALBUMS', 'GET_FULL_ALBUM', 'SET_PLAY', 'SET_PAUSE', 'GET_SUBGENRE']),
        getAlbums() {
            let params = {
                sort: 'releasedAt',
                order: 'desc',
                status: 'published',
                'page': this.ALBUMS_CURRENT_PAGE,
            };
            if (this.id) { // subgenre
                params['subGenres'] = this.id;
            }
            const discovery = this.DISCOVERY === true ? {'playedMusicReleases': false} : '';
            if (this.AUTH_DATA.accessToken) {
                if (this.DISCOVERY === true) {
                    // params['exists[playedMusicReleases.user]'] = false;
                    Object.assign(params, discovery);
                }
            }
            this.GET_EXPLORE_GRID_ALBUMS(params)
                .then(() => {
                    this.$store.dispatch('SET_PLAYED_ZONE', this.EXPLORE_GRID_ALBUMS);
                })
                .catch(err => console.log(`mobile GET_EXPLORE_GRID_ALBUMS, ${err}`))
        },
        play(ev) {
            this.variantPlay = ev;
            let releaseIndex = 0;
            let releaseID;
			let releaseTitle;
            let trackIndex = 0;


            if (ev === 'shuffle') {
                this.$store.commit('SET_SHUFFLE', true);
                releaseIndex = Math.floor(Math.random() * this.EXPLORE_GRID_ALBUMS.length);
            } else {
                this.$store.commit('SET_SHUFFLE', false);
            }

            releaseID = this.EXPLORE_GRID_ALBUMS[releaseIndex].id;
			releaseTitle = this.EXPLORE_GRID_ALBUMS[releaseIndex].title;

	        let params = { id: releaseID, title: releaseTitle };
            this.GET_FULL_ALBUM(params)
                .then(() => {

                    if (ev === 'shuffle') {
                        trackIndex = Math.floor(Math.random() * this.FULL_ALBUM.playList.length);
                    }

                    this.SET_PLAY({
                        fromPlay: 'album_default',
                        track: this.FULL_ALBUM.playList[trackIndex].song,
                        index: 0,
                        album: this.FULL_ALBUM,
                    });
                })
                .catch(err => {
                    console.log(`GET_FULL_ALBUM, ${err}`);
                })
        },

        scrollTop() {
            const el = document.getElementById('top-anchor');
            if (el) {
                el.scrollIntoView({
                    behavior: 'auto',
                });
            }
        },
    },

}
</script>

<style lang="scss">
.skeleton-loader {
    width: 100%;
}
</style>
